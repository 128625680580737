<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- Brand Information -->
    <b-row class="d-flex justify-content-center" style="margin-top: 90px">
      <b-col md="6">
        <b-card
          img-src=""
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <CoolLightBox
                :items="coverArr"
                :index="imgIndex"
                @close="imgIndex = null"
              >
              </CoolLightBox>
              <b-img
                @click="imgIndex = 0"
                :src="bundleData.cover"
                class="rounded"
                style="width: 80px; height: auto"
                variant="light"
              />
            </div>
          </div>
          <h2><span style="color: #0d6efd"></span></h2>
          <h3></h3>
          <h6 class="text-muted"></h6>
          <b-badge
            class="profile-badge"
            style="width: 90%"
            variant="light-primary"
          >
            <div class="text-wrap" style="line-height: 1.7rem">
              <span style="font-size: 20px; width: 100%; margin: auto">
                {{ bundleData.title }}</span
              >
            </div>
          </b-badge>
          <hr class="mb-2" />
          <div class="float-left text-left">
            <p>
              Status:
              <b-badge variant="primary">{{ bundleData.active }}</b-badge>
            </p>
            <p>
              Type:
              <span style="color: gray">{{ bundleData.type }}</span>
            </p>
            <p>
              Start Date:
              <span style="color: gray">{{ bundleData.startdate }}</span>
            </p>
            <p>
              End Date:
              <span style="color: gray">{{ bundleData.enddate }}</span>
            </p>
            <p>
              User Numbers:
              <span style="color: gray">{{ bundleData.usernumbers }}</span>
            </p>
            <p>
              Original Price:
              <span style="color: gray">{{ bundleData.original_price }}</span>
            </p>
            <p>
              Bundle Price:
              <span style="color: gray">{{ bundleData.bundle_price }}</span>
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <div v-if="delCat" class="d-flex justify-content-center">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              :to="{ path: '/bundle/update/' + bundleData.id }"
              variant="primary mr-1 mt-1"
            >
              Update
            </b-button>
            <b-button
              block
              v-if="authPermissions.includes('update') && bundleActive == false"
              @click="activeBundle(bundleData.id)"
              variant="outline-info mr-1 mt-1"
            >
              Active
            </b-button>
            <b-button
              block
              v-if="authPermissions.includes('update') && bundleActive == true"
              @click="deActiveBundle(bundleData.id)"
              variant="outline-info  mr-1 mt-1"
            >
              Deactive
            </b-button>
            <b-button
              block
              v-if="authPermissions.includes('delete')"
              @click="deleteData(bundleData.id)"
              variant="outline-danger"
              class="mr-1 mt-1"
            >
              Delete
            </b-button>
          </div>
          <div v-else class="d-flex justify-content-center">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              @click="restoreBundle(bundleData.id)"
              variant="outline-warning"
              class="mr-1 mt-1"
            >
              Restore
            </b-button>
          </div>
          <hr />
          <div
            v-if="delCat"
            class="
              d-flex
              justify-content-md-center
              align-items-center
              text-center
            "
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ bundleData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Updated at</h6>
              <h3 class="mb-0">
                {{ bundleData.updated_at }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="
              d-flex
              justify-content-md-center
              align-items-center
              text-center
            "
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ bundleData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Deleted at</h6>
              <h3 class="mb-0">
                {{ bundleData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Products">
      <div class="text-right">
        <b-button
          variant="warning mb-1"
          size="sm"
          :to="{ path: '/bundle/products/update/' + bundleData.id }"
          >Update Products</b-button
        >
      </div>
      <div style="overflow-x: scroll">
        <b-table striped hover :items="items" :fields="fields"></b-table>
      </div>
    </b-card>
  </section>
</template>

<script>
// import axios from '@/libs/axios'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
  components: {
    // BSV
    CoolLightBox,
  },
  data() {
    return {
      id: 0,
      bundleData: {},
      errors_back: [],
      showDismissibleAlert: false,
      authPermissions: null,
      imgIndex: null,
      coverArr: [],
      delCat: true,
      bundleActive: true,
      storename: '',
      items: [],
      fields: [
        {
          key: 'product',
          label: 'Product',
        },
        {
          key: 'qty',
          label: 'Qty',
        },
        {
          key: 'gift',
          label: 'gift',
        },
        {
          key: 'original_price',
          label: 'original price',
        },
        {
          key: 'discount',
          label: 'discount',
        },
        {
          key: 'price',
          label: 'price',
        },
        {
          key: 'profit',
          label: 'profit',
        },
        {
          key: 'sale_price',
          label: 'sale price',
        },
      ],
    }
  },
  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('bundles/' + this.id)
        .then((result) => {
          this.bundleData = result.data.data

          this.coverArr.push(this.bundleData.cover)
          this.bundleActive = this.bundleData.active
          this.items.length = 0
          for (let index in this.bundleData.variants) {
            if (this.bundleData.variants[index].translation != null) {
              this.items.push({
                product: this.bundleData.variants[index].translation.name,
                qty: this.bundleData.variants[index].bundle_quantity,
                original_price: this.bundleData.variants[index].original_price,
                profit: this.bundleData.variants[index].profit,
                price: this.bundleData.variants[index].price,
                sale_price: this.bundleData.variants[index].sale_price,
                gift: this.bundleData.variants[index].gift,
                discount: this.bundleData.variants[index].discount,
              })
            } else {
              this.items.push({
                product: 'undefinded',
                qty: this.bundleData.variants[index].bundle_quantity,
                original_price: this.bundleData.variants[index].original_price,
                profit: this.bundleData.variants[index].profit,
                price: this.bundleData.variants[index].price,
                sale_price: this.bundleData.variants[index].sale_price,
                gift: this.bundleData.variants[index].gift,
                discount: this.bundleData.variants[index].discount,
              })
            }
          }
          if (this.bundleData.deleted_at != null) {
            this.delCat = false
          } else {
            this.delCat = true
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    activeBundle(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to active bundle.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('bundles/' + id + '/active-bundle')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deActiveBundle(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to deactive bundle.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('bundles/' + id + '/deactive-bundle')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete bundle.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('bundles/' + id + '/archive-bundle')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restoreBundle(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore bundle.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('bundles/' + id + '/restore-bundle')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
